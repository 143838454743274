import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import z from 'zod';
import axios from 'axios';
import styled from 'styled-components';
import Text from 'components/Text';
import { Loader, LoadingPage, PageContainer, Seo, TextInput } from 'components';
import Header from 'components/Header';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { config } from 'config';
import Button from 'components/PrimaryButton';
import { usePageView, useQuizData } from 'utils/hooks';
import { Quiz, TimeOfBirth } from 'types/quiz';
import { klaviyoLogUserHasPaidAndRegistered } from 'utils/klavyio';
import RegisterCompleted from './components/RegisterCompleted';
import Tracking from 'utils/tracking';
import { PasswordInput } from 'components/inputs/PasswordInput';
import PaymentSteps from '../payments/components/PaymentSteps';
import useQuizAnswersBirthDetails from 'utils/hooks/useQuizAnswersBirthDetails';
import { normalizeStates } from 'utils/localization';

interface FormData {
  email: string;
  emailConfirm: string;
  password?: string;
  repeatedPassword?: string;
}

const defaultValues: FormData = {
  email: '',
  emailConfirm: '',
  password: undefined,
  repeatedPassword: undefined,
};

const validateSchema = z
  .object({
    email: z
      .string({ required_error: 'Please enter your email' })
      .email('Please enter a valid email address'),
    emailConfirm: z.string(),
    password: z
      .string({ required_error: 'Please enter your password' })
      .min(6, 'Password must be no shorter than 6 symbols'),
    repeatedPassword: z.string({
      required_error: 'Please confirm your password',
    }),
  })

  .refine(data => data.password === data.repeatedPassword, {
    message: 'Confirm password do not match with your password',
    path: ['repeatedPassword'],
  });

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 1rem;
  max-width: 30.75rem;
  margin: 0 auto;
  min-height: calc(100vh - 68px);
  @media ${tablet} {
    align-items: unset;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-family: 'Afacad', sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  margin-top: 2.25rem;
  max-width: 28.75rem;
  margin-bottom: 0.5rem;

  @media ${tablet} {
    font-size: 1.625rem;
    font-style: normal;
    line-height: 120%;
    margin-top: 1.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  max-width: 28.75rem;

  @media ${tablet} {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const Form = styled.form`
  width: 100%;
  max-width: 560px;
  padding: 1.5rem 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media ${tablet} {
    width: 100%;
    max-width: 100%;
    border: none;
    border-radius: 0px;
  }
`;

const StyledButton = styled(Button)`
  max-width: 28.75rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.mgSecondary100};
  border-color: ${({ theme }) => theme.colors.mgSecondary100};
  border-radius: 100px;
  margin: 0 auto;
`;

const StyledPasswordInput = styled(PasswordInput)`
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background: ${({ theme }) => theme.colors.white};
  font-size: 1rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const StyledEmailInput = styled(TextInput)`
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background: ${({ theme }) => theme.colors.white};
  font-size: 1rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const FormContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media ${tablet} {
    gap: 0.4rem;
  }
`;

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.375rem 1rem 1.75rem;
  width: 100%;
  background-color: #fff;
  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;

const Register: FC = () => {
  const [loading, setLoading] = useState(false);
  const { isTablet } = useQuery();

  const [errors, setErrors] =
    useState<Partial<Record<keyof FormData, string>>>();
  const [formValues, setFormValues] = useState<FormData>(defaultValues);
  const [isRegisterComplete, setIsRegisterComplete] = useState<boolean>(false);

  const { quiz_answers, user, code, geolocation } = useSelector(
    (state: AppState) => state.user,
  );
  const upgradeSequences = useQuizData('upgradeSequences');

  const data = useQuizData('register');

  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    client_code: code,
  });

  const { goToUpgrade, goToSuccess } = useRouter();

  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const caseParam = new URLSearchParams(location.search).get('case');
  const isFromSupportCase = caseParam === 'support';
  const { year, month, day, hours, minutes } = useQuizAnswersBirthDetails();

  const convertNumberToFixedString = (value: number) =>
    value.toString().padStart(2, '0');

  useEffect(() => {
    if (user) {
      formValues.email = user.email;
      formValues.emailConfirm = user.email;
      setFormValues(formValues);
    }
  }, [user]);

  const getTwentyFourHourTime = (timeOfBirth: TimeOfBirth) => {
    if (timeOfBirth === null) {
      return null;
    }

    const amPmString = `${timeOfBirth.hours}:${timeOfBirth.minutes} ${timeOfBirth.ampm}`;
    const date = new Date(`1/1/2024 ${amPmString}`);

    return { hour: date.getHours(), minute: date.getMinutes() };
  };

  const buildPartnerBirthDetails = () => {
    const relationshipStatus = quiz_answers?.relationship_status;

    if (relationshipStatus === 'single' || relationshipStatus === 'crush') {
      return null;
    }

    const gender = quiz_answers?.partner_gender || '';

    const zodiac = quiz_answers?.partner_zodiac || '';
    const birthDateAns = quiz_answers?.partner_birth_date;
    const birthDate = new Date(birthDateAns?.date!);
    const birthDateString = `${birthDate.getFullYear()}${(
      birthDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}${birthDate.getDay().toString().padStart(2, '0')}`;

    const personName = 'Partner';

    const partnerBirthTime = quiz_answers?.partner_birth_time;

    const time = getTwentyFourHourTime(partnerBirthTime!) ?? null;
    const timeString = `${time?.hour}${time?.minute
      .toString()
      .padStart(2, '0')}`;

    const birthPlace = quiz_answers?.partner_birth_place;
    const birthPlaceFullName = birthPlace
      ? `${birthPlace?.main_text}, ${birthPlace?.secondary_text}`
      : null;

    return {
      personName,
      date: birthDateString,
      time: timeString || '',
      gender,
      zodiacSign: zodiac,
      relationshipType: 'romantic',
      birthPlaceFullName: birthPlaceFullName || '',
    };
  };

  const handleRegister = async (data: FormData) => {
    setLoading(true);

    Tracking.trackCTAButton(location.pathname);
    const userData = {
      email: data.email,
      password: data.password,
    };

    const funnelData = {
      code: code,
      birth: {
        date: `${year}${convertNumberToFixedString(
          month,
        )}${convertNumberToFixedString(day)}`,
        time: `${convertNumberToFixedString(hours)}${convertNumberToFixedString(
          minutes,
        )}`,
      },
      photoUrl: null,
      gender: quiz_answers?.gender || '',
      isQuizComplete: true,
      place: {
        lat: quiz_answers?.birth_place.lat || 51.5072178,
        lng: quiz_answers?.birth_place.lng || -0.1275862,
        mainText: quiz_answers?.birth_place.main_text || 'London',
        secondaryText: quiz_answers?.birth_place.secondary_text || 'UK',
      },
    };

    const partnerDetails = buildPartnerBirthDetails();

    try {
      await axios.post(config.FIREBASE_REGISTER_URL, {
        userCredentials: userData,
        userDetails: funnelData,
        compatibilityMatch: partnerDetails || undefined,
      });

      Tracking.trackRegistration(funnelData.code);

      klaviyoLogUserHasPaidAndRegistered(data.email);
      // if user came from support email - prevent going to upgrade sequence
      if (isFromSupportCase) {
        setIsRegisterComplete(true);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error(error, 'this is our error');

      setLoading(false);
    }
    goToSuccess();
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.scrollIntoView();
  };

  const validateInputs = async (data: FormData) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse(data);
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    setErrors(validationErrors);
    return validationErrors;
  };

  const validateInput = async (data: Partial<FormData>, field: string) => {
    const validationErrors: Record<string, string> = {};
    try {
      validateSchema.parse({
        ...formValues,
        [field]: data[field as keyof FormData],
      });
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.issues.forEach(e => {
          if (e.path[0] !== field) return;
          validationErrors[e.path[0]] = e.message;
        });
      }
    }
    const isEmpty = Object.keys(validationErrors).length === 0;
    if (isEmpty) {
      return setErrors({ ...errors, [field]: null });
    }
    return setErrors({ ...errors, ...validationErrors });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = await validateInputs(formValues);
    if (Object.keys(validationErrors).length === 0) {
      handleRegister(formValues);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
    await validateInput(
      { [event.target.name]: event.target.value },
      event.target.name,
    );
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header
        logoVariant="center"
        hasDivider={false}
        hasShadow={true}
        color={isRegisterComplete ? 'coldGrey' : 'light0'}
      />

      {isRegisterComplete ? (
        <RegisterCompleted />
      ) : (
        <PageContainer with100vh={true} bgColor={data.bgColor}>
          {/* <PaymentStepsContainer>
            <PaymentStepsStyled {...data?.paymentSteps} />
          </PaymentStepsContainer> */}
          <ContentContainer>
            <Title type={isTablet ? 'h3' : 'h1S600'} color="dark100">
              {data?.title}
            </Title>
            <Subtitle>{data?.subtitle}</Subtitle>

            <Form onSubmit={handleSubmit}>
              <FormContentContainer>
                <InputWrapper>
                  <StyledEmailInput
                    name="email"
                    defaultValue={user?.email ?? ''}
                    label={data?.emailLabel}
                    error={errors?.email}
                    disabled={true}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledEmailInput
                    name="emailConfirm"
                    defaultValue={user?.email ?? ''}
                    label={data?.confirmEmailLabel}
                    error={errors?.emailConfirm}
                    disabled={true}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledPasswordInput
                    type="password"
                    name="password"
                    label={data?.passwordLabel}
                    error={errors?.password}
                    disabled={loading}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder="Enter here"
                    required
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledPasswordInput
                    type="password"
                    name="repeatedPassword"
                    label={data?.confirmPasswordLabel}
                    error={errors?.repeatedPassword}
                    disabled={loading}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder="Enter here"
                    required
                  />
                </InputWrapper>
              </FormContentContainer>

              <Wrapper>
                <StyledButton type="submit" loading={loading}>
                  {data?.submitBtnTitle}
                </StyledButton>
              </Wrapper>
            </Form>
          </ContentContainer>
        </PageContainer>
      )}
    </>
  );
};
const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 0;
  width: 100%;
  margin-top: auto;

  @media ${tablet} {
    padding: 1rem 0;
  }
`;

const InputWrapper = styled.div`
  margin-top: 0.6rem;
`;

export default Register;
