import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { AppState } from 'state/types';

const extractDateComponents = (dateString: string) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return { year, month, day };
};

const convertTo24HourFormat = (time: {
  hours: number;
  minutes: number;
  ampm: string;
}) => {
  if (!time) {
    return {
      hours: 0,
      minutes: 0,
    };
  }
  let hours = Number(time.hours);
  const minutes = Number(time.minutes);
  const ampm = time.ampm;

  if (ampm === 'PM' && hours < 12) {
    hours += 12;
  } else if (ampm === 'AM' && hours === 12) {
    hours = 0;
  }

  return {
    hours,
    minutes,
  };
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
};

const useQuizAnswersBirthDetails = () => {
  const quiz_answers = useSelector(
    (state: AppState) => state.user.quiz_answers,
  );

  const birthDetails = useMemo(() => {
    if (!quiz_answers) return null;

    const { year, month, day } = extractDateComponents(
      quiz_answers?.birth_date.date as string,
    );

    const defaultBirthTime = {
      hours: 12,
      minutes: 0,
      ampm: 'PM',
    };

    const resolvedBirthTime = quiz_answers?.birth_time || defaultBirthTime;

    const { hours, minutes } = convertTo24HourFormat(
      resolvedBirthTime as {
        hours: number;
        minutes: number;
        ampm: string;
      },
    );
    const fullDate = formatDate(quiz_answers?.birth_date.date as string);

    return { year, month, day, hours, minutes, fullDate };
  }, [quiz_answers]);

  return birthDetails;
};

export default useQuizAnswersBirthDetails;
