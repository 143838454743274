import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import CircleChecked from 'assets/icons/payments/circle-checked.svg';
import CircleUnchecked from 'assets/icons/payments/circle-unchecked.svg';
import { tablet } from 'styles/breakpoints';

interface PaymentStepsProps {
  firstStepTitle: string;
  secondStepTitle: string;
  thirdStepTitle: string;
  isSuccess?: boolean;
  bgColor?: string;
}

interface DotDividerProps {
  hasOpacity?: boolean;
}

const Container = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  padding: 0.75rem 1rem;
`;

const Inner = styled.div`
  max-width: 56.25rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
  @media ${tablet} {
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 6.25rem;
  gap: 0.25rem;

  svg {
    fill: ${({ theme }) => theme.colors.mgPrimary75};
  }

  @media ${tablet} {
    width: 4.3125rem;
  }
`;

const DotTitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.012rem;
  @media ${tablet} {
    text-align: center;
    font-size: 0.5625rem;
    line-height: normal;
    letter-spacing: normal;
  }
`;

const DotDividerContainer = styled.div`
  flex: 1;
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: -1.12rem;
  margin-right: -1.12rem;

  @media ${tablet} {
    margin-left: -0.38rem;
    margin-right: -0.38rem;
  }
`;

const DotDivider = styled.div<DotDividerProps>`
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.mgPrimary75};
  opacity: ${({ hasOpacity }) => (hasOpacity ? 0.25 : 1)};
`;

const PaymentSteps: FC<PaymentStepsProps & HTMLAttributes<HTMLDivElement>> = ({
  firstStepTitle,
  secondStepTitle,
  thirdStepTitle,
  isSuccess,
  bgColor,
  ...props
}) => {
  const renderDot = (dot?: string) => {
    switch (dot) {
      case 'tick':
        return <CircleTick fill="#423D81" width={24} height={24} />;
      case 'checked':
        return <CircleChecked fill="#423D81" width={24} height={24} />;
      default:
        return <CircleUnchecked fill="#423D81" width={24} height={24} />;
    }
  };

  return (
    <Container bgColor={bgColor!} {...props}>
      <Inner>
        <StepContainer>
          {renderDot('tick')}
          <DotTitle>{firstStepTitle}</DotTitle>
        </StepContainer>
        <DotDividerContainer>
          <DotDivider />
        </DotDividerContainer>
        <StepContainer>
          {isSuccess ? renderDot('tick') : renderDot('checked')}
          <DotTitle>{secondStepTitle}</DotTitle>
        </StepContainer>
        <DotDividerContainer>
          <DotDivider hasOpacity />
        </DotDividerContainer>
        <StepContainer>
          {isSuccess ? renderDot('checked') : renderDot()}
          <DotTitle>{thirdStepTitle}</DotTitle>
        </StepContainer>
      </Inner>
    </Container>
  );
};

export default PaymentSteps;
